<template>
  <div class="app-sounds">
    <div class="back" @click="$router.push('/profile')">
      <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 1L1 8L8 15" stroke="white" stroke-width="2" stroke-linejoin="round"/>
      </svg>
    </div>
    <Preloader v-if="!loading" />
    <div class="portrait" v-if="orientation === 'portrait' && loading">
      <div class="container">
        <div class="title">Отгаданные песни</div>
        <div v-if="sounds.length == 0">У Вас нет ни одной отгаданной песни!</div>
        <div v-else class="sounds">
          <div class="sound" v-for="sound of sounds" :key="sound._id">
            <div class="info">
              {{ sound.name }}
            </div>
            <a v-if="sound.sound" class="download" :href="sound.sound" :download="sound.name + ' - ' + sound.executor_id.map(e => e.name).join(', ') + '.' + sound.sound.split('.').pop()">
              <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.46967 14.5303C8.76256 14.8232 9.23744 14.8232 9.53033 14.5303L14.3033 9.75736C14.5962 9.46447 14.5962 8.98959 14.3033 8.6967C14.0104 8.40381 13.5355 8.40381 13.2426 8.6967L9 12.9393L4.75736 8.6967C4.46447 8.40381 3.98959 8.40381 3.6967 8.6967C3.40381 8.98959 3.40381 9.46447 3.6967 9.75736L8.46967 14.5303ZM8.25 -3.27836e-08L8.25 14L9.75 14L9.75 3.27836e-08L8.25 -3.27836e-08Z" fill="white"/>
                <path d="M1 13.5V13.5C1 16.5376 3.46243 19 6.5 19L11.5 19C14.5376 19 17 16.5376 17 13.5V13.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="bevel"/>
              </svg>
            </a>
            <button v-else class="btn disabled">
              <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.46967 14.5303C8.76256 14.8232 9.23744 14.8232 9.53033 14.5303L14.3033 9.75736C14.5962 9.46447 14.5962 8.98959 14.3033 8.6967C14.0104 8.40381 13.5355 8.40381 13.2426 8.6967L9 12.9393L4.75736 8.6967C4.46447 8.40381 3.98959 8.40381 3.6967 8.6967C3.40381 8.98959 3.40381 9.46447 3.6967 9.75736L8.46967 14.5303ZM8.25 -3.27836e-08L8.25 14L9.75 14L9.75 3.27836e-08L8.25 -3.27836e-08Z" fill="white"/>
                <path d="M1 13.5V13.5C1 16.5376 3.46243 19 6.5 19L11.5 19C14.5376 19 17 16.5376 17 13.5V13.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="bevel"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="landscape" v-if="orientation === 'landscape' && loading">
      <div class="container">
        <div class="title">Отгаданные песни</div>
        <div v-if="sounds.length == 0">У Вас нет ни одной отгаданной песни!</div>
        <div v-else class="sounds">
          <div class="sound" v-for="sound of sounds" :key="sound._id">
            <div class="info">
              {{ sound.name }}
            </div>
            <a v-if="sound.sound" class="download" :href="sound.sound" :download="sound.name + ' - ' + sound.executor_id.map(e => e.name).join(', ') + '.' + sound.sound.split('.').pop()">
              <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.46967 14.5303C8.76256 14.8232 9.23744 14.8232 9.53033 14.5303L14.3033 9.75736C14.5962 9.46447 14.5962 8.98959 14.3033 8.6967C14.0104 8.40381 13.5355 8.40381 13.2426 8.6967L9 12.9393L4.75736 8.6967C4.46447 8.40381 3.98959 8.40381 3.6967 8.6967C3.40381 8.98959 3.40381 9.46447 3.6967 9.75736L8.46967 14.5303ZM8.25 -3.27836e-08L8.25 14L9.75 14L9.75 3.27836e-08L8.25 -3.27836e-08Z" fill="white"/>
                <path d="M1 13.5V13.5C1 16.5376 3.46243 19 6.5 19L11.5 19C14.5376 19 17 16.5376 17 13.5V13.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="bevel"/>
              </svg>
            </a>
            <button v-else class="btn disabled">
              <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.46967 14.5303C8.76256 14.8232 9.23744 14.8232 9.53033 14.5303L14.3033 9.75736C14.5962 9.46447 14.5962 8.98959 14.3033 8.6967C14.0104 8.40381 13.5355 8.40381 13.2426 8.6967L9 12.9393L4.75736 8.6967C4.46447 8.40381 3.98959 8.40381 3.6967 8.6967C3.40381 8.98959 3.40381 9.46447 3.6967 9.75736L8.46967 14.5303ZM8.25 -3.27836e-08L8.25 14L9.75 14L9.75 3.27836e-08L8.25 -3.27836e-08Z" fill="white"/>
                <path d="M1 13.5V13.5C1 16.5376 3.46243 19 6.5 19L11.5 19C14.5376 19 17 16.5376 17 13.5V13.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="bevel"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Preloader from '../Preloader';
import Axios from 'axios';

export default {
  name: 'GameSoundsMain',
  data() {
    return {
      orientation: this.$store.getters.orientation,
      loading: false,
      sounds: null,
      sound: null,
      user: this.$store.getters.user,
      token: this.$store.getters.token,
    }
  },
  mounted() {
    Axios
      .get(`/api/user/sounds`, {
        headers: {
          token: this.token
        }
      })
      .then(res => {
        res.data = res.data.filter(s => s.sound);
        this.sounds = res.data;
        this.loading = true;
      })
  },
  watch: {
    '$store.state.orientation': function() {
      if (this.$store.state.orientation) {
        this.orientation = this.$store.state.orientation;
      }
    }
  },
  components: {
    Preloader
  }
}
</script>

<style lang="scss" scoped>
.back {
  position: absolute;
  top: 25px;
  left: 25px;
  cursor: pointer;
}
.app-sounds {
  .landscape {
    .container {
      padding-top: 33px;
      padding-left: 25px;
      .title {
        font-size: 22px;
      }
      .sounds {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .sound {
          width: 46%;
        }
      }
    }
  }
  .container {
    padding-top: 100px;
    .title {
      font-size: 26px;
      margin-bottom: 20px;
    }
    .sounds {
      .sound {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        .info {
          background: rgba(255, 255, 255, 0.2);
          border-radius: 10px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          flex-basis: 82%;
          padding: 10px 15px;
        }
        .download {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: #281D3C;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        button {
          width: 40px !important;
          height: 40px !important;
          border-radius: 50% !important;
          background: rgba(255, 255, 255, 0.2);
          &.disabled {
            opacity: 0.5;
            cursor: default;
          }
        }
      }
    }
  }
}
</style>