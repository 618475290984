<template>
  <GameSoundsMain />
</template>

<script>
import axios from "axios";
import GameSoundsMain from "@/components/game/profile/GameSoundsMain";

export default {
  name: "GameSounds",
  components: {
    GameSoundsMain,
  },
};
</script>

<style>
</style>